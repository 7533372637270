import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  setImagePath: "[setImagePath] Action",
  deleteImagePath: "[deleteImagePath] Action",
};

const initialAuthState: ICenterState = {
  image: undefined,
};

export interface ICenterState {
  image?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-image", whitelist: ["image"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.setImagePath: {
        const image = action.payload?.image;
        return { ...state, image };
      }
      case actionTypes.deleteImagePath: {
        return { ...state, image: undefined }; // Set the image to undefined
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setImagePath: (image: string) => ({
    type: actionTypes.setImagePath,
    payload: { image },
  }),
  deleteImagePath: () => ({
    type: actionTypes.deleteImagePath, // New action
  }),
};

// export const setImagePath = (data: any) => ({
//   type: "SET_IMAGE_PATH",
//   payload: data,
// });
// const initialState = {
//   data: "", // Default value
// };

// // Reducer to handle image path actions
// export const imageReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case "SET_IMAGE_PATH":
//       return { ...state, data: action.payload };
//     default:
//       return state;
//   }
// };
