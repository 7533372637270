/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import * as header from "../../../../_metronic/layout/components/header/redux/HeadersRedux";
import {
  login,
  getCountryCodeList,
  getTimeZoneList,
  GetActiveBranches,
} from "../redux/AuthCRUD";
import { toast } from "react-toastify";
// import useFetchBranches from "../../../hooks/useFetchBranches";
import localStorage from "redux-persist/es/storage";
import { useIntl } from "react-intl";

const initialValues = {
  email_mobile: "",
  password: "",
  country_code: "",
  login_type: "website",
  device_token: "wddwedq",
  public_ip_address: "127.0.1",
  broswer: "chrome",
  browser_version: "54",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
//  const getCountryCode(){
//   const data=localStorage.getItem('CountryCode')
//   const countryCode=JSON.parse('data')
// }
//  async function getStore{
//   const previosEmail: any =await localStorage?.getItem("previousEmail");
// }
export function Login() {
  let emailID: any;
  const localstorageGet = async () => {
    const previosEmail = await localStorage.getItem("previousEmail");

    if (previosEmail && previosEmail !== "undefined") {
      try {
        emailID = JSON.parse(previosEmail);
        return emailID;
      } catch (error) {
        console.error("Failed to parse JSON from localStorage:", error);
      }
    }
    return null;
  };

  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  // const getBranches = useFetchBranches();
  const loginSchema = Yup.object().shape({
    email_mobile: Yup.string()
      .email(intl.formatMessage({ id: "VALIDATION.EMAIL.WRONG_FORMAT" }))
      .min(3, intl.formatMessage({ id: "VALIDATION.EMAIL.MIN" }))
      .max(50, intl.formatMessage({ id: "VALIDATION.EMAIL.MAX" }))
      .required(intl.formatMessage({ id: "VALIDATION.EMAIL.REQUIRED" })),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: "VALIDATION.PASSWORD.MIN" }))
      .max(50, intl.formatMessage({ id: "VALIDATION.PASSWORD.MAX" }))
      .required(intl.formatMessage({ id: "VALIDATION.PASSWORD.REQUIRED" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const storedEmailID = await localstorageGet();

      if (
        storedEmailID &&
        storedEmailID?.email !== values?.email_mobile?.trim()
      ) {
        // Clear keys if emails match
        localStorage.removeItem("ActiveBranch");
        localStorage.removeItem("ActiveBranchList");
      }

      localStorage.setItem(
        "previousEmail",
        JSON.stringify({ email: values?.email_mobile?.trim() })
      );

      setLoading(true);
      setTimeout(() => {
        localStorage.setItem("loginDetails", JSON.stringify(values));

        login(values)
          .then((responce) => {
            if (responce.status === 200) {
              const {
                role_id,
                name,
                access_token,
                plans,
                vFirstName,
                vLastName,
                vEmail,
                ActivePlanModules,
                permissionData,
                iAdminID,
                userType,
                activeplan_UUID,
                superAdminDetails,
                superAdminSetting,
                notActivePlanMessage,
                percentage,
              } = responce.data.data;
              if (userType === "employee") {
                const {
                  employee_id,
                  name,
                  vCountryCode,
                  vMobile,
                  employee_uuid,
                } = responce?.data?.data;
                const newObj = {
                  employee_id,
                  name,
                  vCountryCode,
                  vMobile,
                  employee_uuid,
                };

                localStorage.setItem("employeeDetails", JSON.stringify(newObj));
              }

              const newObj1 = {
                name,
                iAdminID,
                percentage,
              };

              localStorage.setItem(
                "ChennalPartnerDetails",
                JSON.stringify(newObj1)
              );
              localStorage.setItem("activeUserType", JSON.stringify(userType));
              localStorage.setItem(
                "footer",
                JSON.stringify(superAdminDetails || superAdminSetting)
              );
              console.log("FooterLogin:", superAdminDetails);
              localStorage.setItem(
                "permissionData",
                JSON.stringify(permissionData)
              );
              localStorage.setItem(
                "activePlanModules",
                JSON.stringify(ActivePlanModules)
              );
              localStorage.setItem(
                "ActionplanUUID",
                JSON.stringify(activeplan_UUID)
              );

              getCountryCodeList().then((response) => {
                localStorage.setItem(
                  "CountryCurrencycode",
                  JSON.stringify(response.data.data)
                );
              });
              getTimeZoneList().then((response) => {
                localStorage.setItem(
                  "timeZoneList",
                  JSON.stringify(response.data.data)
                );
              });

              GetActiveBranches().then((response) => {
                localStorage.setItem(
                  "ActiveBranchList",
                  JSON.stringify(response.data.data)
                );
              });
              // getBranches();
              //if(role_id !== 2 && role_id !== 3) {
              setLoading(false);
              dispatch(auth.actions.login(access_token));
              toast.success("Login SuccessFully");
              dispatch(auth.actions.getModuleDetails(ActivePlanModules));
            } else {
              if (responce.data.replyMSG) {
                setStatus(responce?.data?.replyMSG);
              } else {
                setStatus(responce?.data?.replyMsg);
              }
              setLoading(false);
            }
            // }
            // else {
            //   setLoading(false)
            //   setSubmitting(false)
            //   setStatus("You do not have access")
            // }

            //getBranches();
            //creta temp veriable
            // if (role_id === 2 || role_id === 3) {
            //setLoading(false)
            // if (plan.paymentStatus !== 1) {
            //    history.push(`/auth/checkout`, {planData, userData,userId: iAdminID,
            //    })
            //    toast.warning("Please complete your payment")
            //  } else {
            // dispatch(auth.actions.login(access_token));
            // toast.success("Login SuccessFully");
            // console.log(access_token, role_id, "data");
            //for getting all branches
            // getBranches();
            // }

            // }
            // else {
            //   setLoading(false)
            //   setSubmitting(false)
            //   setStatus("You do not have access")
            // }
          })
          .catch(() => {
            setStatus("check Login details and internet Connection ..!");
            setLoading(false);
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="w-lg-500px bg-white rounded shadow-sm p-5 p-lg-7 mx-auto">
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* begin::Heading */}
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "MODULE.AUTH.HEADING1" })}
          </h1>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <></>
        )}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="required form-label fs-6 fw-bolder text-dark">
            {" "}
            {intl.formatMessage({ id: "MODULE.AUTH.EMAIL" })}
          </label>
          <input
            placeholder="Email"
            {...formik.getFieldProps("email_mobile")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.email_mobile && formik.errors.email_mobile,
              },
              {
                "is-valid":
                  formik.touched.email_mobile && !formik.errors.email_mobile,
              }
            )}
            type="email"
            name="email_mobile"
            autoComplete="off"
          />
          {formik.touched.email_mobile && formik.errors.email_mobile && (
            <div className="fv-plugins-message-container">
              <span className="error">{formik.errors.email_mobile}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <div className="d-flex justify-content-between mt-n5">
            <div className="d-flex flex-stack mb-2">
              {/* begin::Label */}
              <label className="required form-label fw-bolder text-dark fs-6 mb-0">
                {intl.formatMessage({ id: "MODULE.AUTH.PASSWORD" })}
              </label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to="/auth/forgot-password"
                className="link-primary fs-6 fw-bolder"
                style={{ marginLeft: "5px" }}
              >
                {intl.formatMessage({ id: "MODULE.AUTH.FORGOTPASSWORD?" })}
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <div className="position-relative">
            <input
              type={`${passShow ? "text" : "password"}`}
              autoComplete="off"
              placeholder="Password"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />{" "}
            <span
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
              style={{ cursor: "pointer" }}
              onClick={() => setPassShow(!passShow)}
            >
              {passShow ? (
                <FaEyeSlash className="fs-4 text-primary me-8" />
              ) : (
                <FaEye className="fs-4 text-muted me-8" />
              )}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span className="error">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className="d-flex justify-content-between mt-n5 mb-5">
          <Link
            to="/auth/registration"
            className="link-primary fs-6 fw-bolder"
            style={{ marginLeft: "5px" }}
          >
            {intl.formatMessage({ id: "MODULE.AUTH.NEWREGISTER" })}
          </Link>
          <Link
            to="/auth/memberlogin"
            className="link-primary fs-6 fw-bolder"
            style={{ marginLeft: "5px" }}
          >
            {intl.formatMessage({ id: "MODULE.AUTH.MEMBERLOGIN" })}
          </Link>
        </div>

        {/* begin::Action */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className="indicator-label">
                {" "}
                {intl.formatMessage({ id: "MODULE.AUTH.SIGNIN" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "COMMON.PLEASEWAIT" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* seperator */}
        <div className="separator separator-content my-5"></div>
        {/* login otp link  */}
        {/* <div className="text-center">
          <Link
            to="/auth/otp-login"
            className="link-primary fs-6 fw-bolder"
            style={{ marginLeft: "5px" }}
          >
            {intl.formatMessage({ id: "MODULE.AUTH.LOGINOTP" })}
          </Link>
        </div> */}
        {/* end::Action */}
      </form>
    </div>
  );
}
