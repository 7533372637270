import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_DASHBOARD = `${API_URL}/v1/dashboard/details`;
export const SEND_NOTIFICATION = `${API_URL}/v1/dashboard/dueamountnotification`;
export const ENQUIRY_NOTIFICATION = `${API_URL}/v1/dashboard/enquirynotification`;
export const EXPIRED_NOTIFICATION = `${API_URL}/v1/dashboard/todayexpirenotification`;
export const INVOICE_NOTIFICATION = `${API_URL}/v1/dashboard/invoicenotification`;
export const FOLLOWUP_NOTIFICATION = `${API_URL}/v1/dashboard/followupnotification`;
export const MEMBER_BIRTHDAY_NOTIFICATION = `${API_URL}/v1/dashboard/memberBirthDaynotification`;
export const EMPLOYEE_BIRTHDAY_NOTIFICATION = `${API_URL}/v1/dashboard/empBirthDaynotification`;
export const GET_LIST_REMAINGBALANCE = `${API_URL}/v1/receipt/outstanding`;

export function getDashboard(uuid: any, data: any) {
  return axios.get(`${GET_DASHBOARD}/${uuid}?${data}`).catch((error) => {
    return error.response;
  });
}
export function SendNotification(data: any) {
  return axios.post(`${SEND_NOTIFICATION}`, data).catch((error) => {
    return error.response;
  });
}
export function EnquiryNotifications(data: any) {
  return axios.post(`${ENQUIRY_NOTIFICATION}`, data).catch((error) => {
    return error.response;
  });
}
export function ExpireNotification(data: any) {
  return axios.post(`${EXPIRED_NOTIFICATION}`, data).catch((error) => {
    return error.response;
  });
}
export function InvoiceNotification(data: any) {
  return axios.post(`${INVOICE_NOTIFICATION}`, data).catch((error) => {
    return error.response;
  });
}
export function FollowupNotification(data: any) {
  return axios.post(`${FOLLOWUP_NOTIFICATION}`, data).catch((error) => {
    return error.response;
  });
}
export function MemberBirthdayNotification(data: any) {
  return axios.post(`${MEMBER_BIRTHDAY_NOTIFICATION}`, data).catch((error) => {
    return error.response;
  });
}
export function EmployeeBirthdayNotification(data: any) {
  return axios
    .post(`${EMPLOYEE_BIRTHDAY_NOTIFICATION}`, data)
    .catch((error) => {
      return error.response;
    });
}

export function getRemaingBalance(payloadData: any) {
  return axios.post(GET_LIST_REMAINGBALANCE, payloadData);
}
