import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { HeaderUserMenu, QuickLinks } from "../../../partials";
import { RootState } from "../../../../setup";
import { Languages } from "./Languages";
import { useLayout } from "../../core";
import { shallowEqual, useSelector } from "react-redux";
// import { Dropdown3 } from "../../../partials/content/dropdown/Dropdown3";
import HeaderBranchMenu from "../../../partials/layout/header-menus/HeaderBranchMenu";
import { useLang } from "../../../i18n/Metronici18n";
import { getallmodules } from "./redux/HeadersCRUD";
import { useIntl } from "react-intl";
import RemainModal from "./RemainModal";
import { ActivePaymentMode } from "../../../../app/modules/finance/modules/invoice/component/redux/invoiceCRUD";
import {
  ActiveBatchList,
  ActiveEmpList,
  ActiveEnquiryList,
  ActiveOccupationList,
  ActivePackageList,
  GetGenders,
  GetMaritalStatus,
} from "../../../../app/modules/members/redux/MembersCRUD";
import BranchModal from "../../../../app/modules/members/component/modal/MemberModal";
// import swal from "sweetalert";
// import { Modal } from "react-bootstrap";
// import { useCallback } from "react";
// import { boolean } from "yup";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
  toolbarButtonIconSizeClass = "svg-icon-1";

const Topbar: FC = () => {
  const intl = useIntl();
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as any;
  const [show, setShow] = useState<boolean>(false);
  const [showMember, setShowMember] = useState<boolean>(false);
  // console.log("user", user);
  const { config } = useLayout();
  const planExpireINDays = 10;
  const selectedbranch: any = useSelector(
    (state: any) => state.auth.selectedBranch
  );
  let activeBranch: any = localStorage.getItem("ActiveBranch");
  const selectedActiveBranch = JSON.parse(activeBranch);
  const ActiveBranchUUID = selectedActiveBranch?.iBranchUUID;
  let data1: any = localStorage?.getItem("activeUserType");
  const userType = JSON.parse(data1);
  const [activeEnqList, setActiveEnqList] = useState<any>([]);
  const [activeEmpList, setActiveEmpList] = useState<any>([]);
  const [activeOccList, setActiveOccList] = useState<any>([]);
  // const [branch_id, setBranch_id] = useState<any>("0");
  const [activePackageList, setActivePackageList] = useState<any>([]);
  const [gender, setGender] = useState<any>([]);
  const [maritalStatus, setMaritalStatus] = useState<any>([]);
  const [activeBatchList, setActiveBatchList] = useState<any>([]);
  const [paymodeOpt, setPayModeOpt] = useState<any>([]);
  const [error, setError] = useState<any>("");
  const [from, setFrom] = useState<any>(false);
  // console.log(selectedbranch, ",,,,,,,,,,,selected bracnhc");
  // console.log("aaaaaaaaaaa", selectedActiveBranch);

  const languagesnew: any = useSelector(
    (state: any) => state.headers.languageList
  );
  const lang = useLang();
  const currentLanguage = languagesnew?.find(
    (x: any) => x.languageSlug === lang
  );
  localStorage.setItem("setLanguage", JSON.stringify(currentLanguage));
  // console.log(currentLanguage, "here is langsas");

  const GetActivePayMethod = async (branchUUID: any) => {
    const response = await ActivePaymentMode(branchUUID);
    setPayModeOpt(response?.data?.data);
    // console.log(response?.data, "reponseofactive");
  };
  //start get active enquiry list
  const getActiveEnquiryList = async (branchUUID: any) => {
    const response = await ActiveEnquiryList(branchUUID);
    setActiveEnqList(response?.data?.data);
    // console.log(response.data.data);
  };
  //end get active enquiry list

  //start get active member list
  const getActiveEmpList = async (branchUUID: any) => {
    const response = await ActiveEmpList(branchUUID);
    setActiveEmpList(response?.data?.data);
  };
  //end get active member list

  //start get active occupation list
  const getActiveOccupationList = async (branchUUID: any) => {
    const response = await ActiveOccupationList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActiveOccList(response?.data?.data);
  };
  //end get active occupation list

  //start get active batch list
  const getActiveBatchList = async (branchUUID: any) => {
    const response = await ActiveBatchList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActiveBatchList(response?.data?.data);
    // setBatchData(response?.data?.data);
  };
  //end get active batch list

  //start get active package list
  const getActivePackageList = async (branchUUID: any) => {
    const response = await ActivePackageList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActivePackageList(response?.data?.data);
  };
  //end get active package list

  //start get active gender list
  const getGenders = async (lang: any) => {
    const response = await GetGenders(lang);
    setGender(response?.data?.data);
  };
  //end get active gender list

  //start get active marital list
  const getMaritalStatus = async (lang: any) => {
    const response = await GetMaritalStatus(lang);
    setMaritalStatus(response?.data?.data);
  };

  const handleAddMember = () => {
    let activeBranch: any = localStorage.getItem("ActiveBranch");
    if (activeBranch) {
      getActiveEnquiryList(ActiveBranchUUID);
      getActiveEmpList(ActiveBranchUUID);
      getActiveOccupationList(ActiveBranchUUID);
      getActivePackageList(ActiveBranchUUID);
      getActiveBatchList(ActiveBranchUUID);
      GetActivePayMethod(ActiveBranchUUID);
      getGenders(lang);
      getMaritalStatus(lang);
      setShowMember(true);
      setFrom(true);
    }
  };
  return (
    <>
      <RemainModal show={show} setShow={setShow} />
      <BranchModal
        show={showMember}
        setShow={setShowMember}
        activeEnqList={activeEnqList}
        activeEmpList={activeEmpList}
        activeOccList={activeOccList}
        activePackageList={activePackageList}
        gender={gender}
        maritalStatus={maritalStatus}
        activeBatchList={activeBatchList}
        error={error}
        setError={setError}
        UserType={userType}
        paymodeOpt={paymodeOpt}
        from={from}
        setFrom={setFrom}
      />
      <div className="d-flex align-items-stretch flex-shrink-0">
        {/* begin::User */}

        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
          id="kt_header_language_menu_toggle"
        >
          {/* begin::Toggle */}
          {userType !== "member" ? (
            <div
              className="fs-3 m-2 cursor-pointer"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="add Member"
              onClick={() => {
                handleAddMember();
              }}
            >
              <i className="fas fa-user-plus text-primary"></i>
            </div>
          ) : (
            ""
          )}

          <div
            className={clsx(
              "d-flex align-items-center btn  btn-active-light-primary",
              //toolbarUserAvatarHeightClass,
              //'border border-primary', // Add a border
              "rounded-3", // Apply rounded corners
              "p-3", // Add padding
              "cursor-pointer" // Change cursor on hover
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <div className="symbol symbol-100px me-5 ">
              {currentLanguage?.languageName === undefined
                ? "English"
                : currentLanguage?.languageName}
            </div>
          </div>
          {/* end::Toggle */}
          <Languages />
        </div>

        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
          id="kt_header_Branch_menu_toggle"
        >
          {/* begin::Toggle */}
          {userType !== "member" && (
            <div
              className={clsx(
                "d-flex align-items-center btn  btn-active-light-primary",
                //toolbarUserAvatarHeightClass,
                //'border border-primary', // Add a border
                "rounded-3", // Apply rounded corners
                "p-3", // Add padding
                "cursor-pointer" // Change cursor on hover
              )}
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
            >
              {/* select Active Branch Bar */}
              {/* <div className='symbol symbol-100px me-5 '>
            {selectedbranch===undefined ? ( selectedActiveBranch!==null? selectedActiveBranch?.vBranch :'Select Your Branch' ): selectedbranch?.vBranch}
           
          </div> */}

              <div className="symbol symbol-100px me-5 ">
                {/* {selectedbranch===undefined ? ( selectedActiveBranch!==null? selectedActiveBranch?.vBranch :'Select Your Branch' ): selectedbranch?.vBranch} */}
                {selectedbranch === undefined
                  ? selectedActiveBranch !== null
                    ? selectedActiveBranch?.vBranch
                    : intl.formatMessage({
                        id: "MODULE.BRANCH.SELECT.YOUR.BRANCH",
                      })
                  : selectedbranch?.vBranch}
              </div>
            </div>
          )}
          {userType !== "member" ? (
            <div
              className="notVisibleInMobile bg-danger  rounded rounded-3 p-2 text-light cursor-pointer"
              onClick={() => {
                setShow(true);
              }}
            >
              {`${user.planExpireINDays} ${intl.formatMessage({
                id: "MODULE.TOPBAR.PLANLEFT",
              })}`}
            </div>
          ) : (
            ""
          )}
          {/* end::Toggle */}
          {userType !== "member" && <HeaderBranchMenu />}
        </div>
        {/* end::User */}
        {/* Activities */}
        {userType !== "member" && (
          <>
            <div
              className={clsx(
                "d-flex align-items-center",
                toolbarButtonMarginClass
              )}
            >
              {/* begin::Drawer toggle */}
              <div
                className={clsx(
                  "btn btn-icon btn-active-light-primary",
                  toolbarButtonHeightClass
                )}
                id="kt_activities_toggle"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen032.svg"
                  className={toolbarButtonIconSizeClass}
                />
              </div>
              {/* end::Drawer toggle */}
            </div>

            {/* Quick links */}
            <div
              className={clsx(
                "d-flex align-items-center",
                toolbarButtonMarginClass
              )}
            >
              {/* begin::Menu wrapper */}
              <div
                className={clsx(
                  "btn btn-icon btn-active-light-primary",
                  toolbarButtonHeightClass
                )}
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="bottom"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen025.svg"
                  className={toolbarButtonIconSizeClass}
                />
              </div>
              <QuickLinks />
              {/* end::Menu wrapper */}
            </div>
          </>
        )}
        {/* CHAT */}
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          {/* begin::Menu wrapper */}

          {/* end::Menu wrapper */}
        </div>

        {/* NOTIFICATIONS */}
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          {/* begin::Menu- wrapper */}

          {/* end::Menu wrapper */}
        </div>

        {/* begin::User */}
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
          id="kt_header_user_menu_toggle"
        >
          {/* begin::Toggle */}
          <div
            className={clsx(
              "cursor-pointer symbol",
              toolbarUserAvatarHeightClass
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <div className="symbol symbol-100px me-5">
              <div
                className="symbol-label fs-2 fw-semibold bg-danger text-inverse-danger"
                style={{
                  height: "40px",
                  width: "40px",
                }}
              >
                {user.name?.substring(0, 1).toUpperCase()}
              </div>
            </div>
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}

        {/* begin::Aside Toggler */}
        {config.header.left === "menu" && (
          <div
            className="d-flex align-items-center d-lg-none ms-2 me-n3"
            title="Show header menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_header_menu_mobile_toggle"
            >
              <KTSVG
                path="/media/icons/duotune/text/txt001.svg"
                className="svg-icon-1"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { Topbar };
