import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_DOWNLOAD = `${API_URL}/v1/download/database/`;

export function GetTopbarItems(data: any) {
  return axios.get(GET_DOWNLOAD + data).catch((error) => {
    return error.response;
  });
}
