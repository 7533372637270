// setupAxios.ts
import {
  showLoader,
  hideLoader,
} from "../../app/modules/Genric componant/spinner/redux/spinnerREDUX";
import { History } from "history"; // Import the type for history
import { useLang } from "../../_metronic/i18n/Metronici18n";

export default function setupAxios(
  axios: any,
  store: any,
  history: History,
  lang: any
) {
  let requestCount = 0;

  // List of API paths to exclude from showing the loader
  const excludedPaths = [
    "/v1/user/getdetailByID", // Example path
  ];

  const showLoading = (url: string) => {
    if (excludedPaths.some((path) => url.includes(path))) {
      // Skip showing the loader for excluded paths
      return;
    }
    if (requestCount === 0) {
      store.dispatch(showLoader());
    }
    requestCount++;
  };

  const hideLoading = (url: string) => {
    if (excludedPaths.some((path) => url.includes(path))) {
      // Skip hiding the loader for excluded paths
      return;
    }
    requestCount--;
    if (requestCount === 0) {
      store.dispatch(hideLoader());
    }
  };
  axios.interceptors.request.use(
    (config: any) => {
      // Show loader when the request starts
      showLoading(config.url);

      const {
        auth: { accessToken },
      } = store.getState();
      // const data = localStorage.getItem("setLanguage");
      let activeLanguage = "en"; // Default language

      if (lang) {
        try {
          // const parsedData = JSON.parse(data);
          activeLanguage = lang || "en"; // Fallback to "en" if undefined
        } catch (error) {
          console.error("Failed to parse JSON from localStorage:", error);
        }
      }

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      if (activeLanguage) {
        config.headers["accept-language"] = activeLanguage;
      }

      return config;
    },
    (error: any) => {
      // Hide loader if the request fails
      hideLoading(error.config?.url || "");
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response: any) => {
      // Hide loader when the response is received
      hideLoading(response.config?.url || "");
      return response;
    },
    (error: any) => {
      // If 401, redirect to 403 page using React Router's history
      if (
        error.response &&
        error.response.status === 401 &&
        window.location.pathname !== "/fitnesscenter/pages/unauthorized"
      ) {
        history.push("/pages/unauthorized"); // Use history.push for redirection in v5
      }
      // Hide loader if the response fails
      hideLoading(error.config?.url || "");
      return Promise.reject(error);
    }
  );
}

// src/axios.setup.js
// import {
//   showLoader,
//   hideLoader,
// } from "../../app/modules/Genric componant/spinner/redux/spinnerREDUX";

// export default function SetupAxios(axios: any, store: any) {
//   let requestCount = 0;

//   const showLoading = () => {
//     if (requestCount === 0) {
//       store.dispatch(showLoader()); // Dispatch action to show the loader
//     }
//     requestCount++;
//   };

//   const hideLoading = () => {
//     requestCount--;
//     if (requestCount === 0) {
//       store.dispatch(hideLoader()); // Dispatch action to hide the loader
//     }
//   };

//   axios.interceptors.request.use(
//     (config: any) => {
//       // Show loader when the request starts
//       showLoading();

//       const {
//         auth: { accessToken },
//       } = store.getState();
//       const data = localStorage.getItem("setLanguage");
//       let activeLanguage = "en"; // Default language

//       if (data) {
//         try {
//           const parsedData = JSON.parse(data);
//           activeLanguage = parsedData?.languageSlug || "en"; // Fallback to "en" if undefined
//         } catch (error) {
//           console.error("Failed to parse JSON from localStorage:", error);
//         }
//       }

//       if (accessToken) {
//         config.headers.Authorization = `Bearer ${accessToken}`;
//       }
//       if (activeLanguage) {
//         config.headers["accept-language"] = activeLanguage;
//       }

//       return config;
//     },
//     (error: any) => {
//       // Hide loader if the request fails
//       hideLoading();
//       return Promise.reject(error);
//     }
//   );
//   axios.interceptors.response.use(
//     (response: any) => response,
//     (error: any) => {
//       if (
//         error.response &&
//         error.response.status === 401 &&
//         window.location.pathname !== "/fitnesscenter/pagenotfound/unauthorized"
//       ) {
//         const fullUrl = window.location.pathname;
//         console.log(fullUrl);
//         window.location.href = "/pagenotfound/unauthorized"; // Redirects to /error/403
//       }
//       return Promise.reject(error);
//     }
//   );

//   axios.interceptors.response.use(
//     (response: any) => {
//       // Hide loader when the response is received
//       hideLoading();
//       return response;
//     },
//     (error: any) => {
//       // Hide loader if the response fails
//       hideLoading();
//       return Promise.reject(error);
//     }
//   );
// }

// import { Language } from "prism-react-renderer";
// export default function setupAxios(axios: any, store: any) {
//   axios.interceptors.request.use(
//     (config: any) => {
//       const {
//         auth: { accessToken },
//       } = store.getState();
//       const user = store.getState();
//       // console.log(user, "user");
//       const data: string | null = localStorage.getItem("setLanguage");
//       let activeLanguage = "en"; // Default language

//       if (data) {
//         try {
//           const parsedData = JSON.parse(data);
//           activeLanguage = parsedData?.languageSlug || "en"; // Fallback to "en" if languageSlug is undefined
//         } catch (error) {
//           console.error("Failed to parse JSON from localStorage:", error);
//         }
//       }

//       if (accessToken) {
//         config.headers.Authorization = `Bearer ${accessToken}`;
//       }
//       if (activeLanguage) {
//         config.headers["accept-language"] = activeLanguage;
//       }

//       return config;
//     },
//     (err: any) => {
//       console.log(err);
//       return err.response;
//     }
//   );
//   // axios.interceptors.response.use(
//   //   function(response:any){
//   //     return response;
//   //   },
//   //   function(error:any){
//   //     // Promise.reject(error)
//   //     console.log(error)
//   //     return error.response;
//   //   }
//   // )
// }
