import { useFormik } from "formik";
// import "./setting.css";
import * as Yup from "yup";
import React from "react";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { UpdateCompany } from "../redux/settingCRUD";
import data from "../../../../config.json";
import Select from "react-select";
const SMS = ({
  smsdata,
  setsmsData,
  UpdateUser,
  ActiveBranchUUID,
  ActiveBranchID,
  detailsCompany,
  setSuccessResponse,
  setErrorResponse,
  setErr,
  ActiveBranchSettingUUID,
}: any) => {
  const intl = useIntl();
  const Schemas = Yup.object().shape({
    smsCompany: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.COMPANY" })),
    smsUsername: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.USERNAME" })),
    smsPassword: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.PASSWORD" })),
    smsSenderID: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.SENDERID" })),
    smsStatus: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.Status" })),
  });

  const initialState = {
    smsCompany: UpdateUser?.smsCompany,
    smsUsername: UpdateUser?.smsUsername,
    smsPassword: UpdateUser?.smsPassword,
    smsSenderID: UpdateUser?.smsSenderID,
    smsStatus: UpdateUser?.smsStatus || "Active",
    smsSupplementStatus: UpdateUser?.smsSupplementStatus,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,

    validationSchema: Schemas,

    onSubmit: async (values) => {
      //   console.log(values, "valee");
      setsmsData(values);
      const formData = {
        smsCompany: values.smsCompany,
        smsUsername: values.smsUsername,
        smsPassword: values.smsPassword,
        smsSenderID: values.smsSenderID,
        smsStatus: values.smsStatus,
        smsSupplementStatus: values.smsSupplementStatus || "",
        iBranchSettingUUID:
          UpdateUser?.iBranchSettingUUID || ActiveBranchSettingUUID,
        branchID: ActiveBranchID || "",
        branchUUID: ActiveBranchUUID || "",
        courrencyID: UpdateUser?.iCountryCurrencyID || "",
        dataFormate: UpdateUser?.idateFormate || "",
        timeFormate: UpdateUser?.itimeFormate || "",
        membershipFreezeShargeable:
          UpdateUser?.membershipFreezeChargeable || "",
        invoiceNotes: UpdateUser?.InvoiceNotes || "",
        invoiceSupplementNotes: UpdateUser?.suppInvocieNotes || "",
        invoiceprefx: UpdateUser?.inVoicePrefix || "",
        expiredPackageDays: UpdateUser?.expiredListInDays || "",
        whatsAppCompany: UpdateUser?.whatsAppCompany || "",
        whatsAppUsername: UpdateUser?.whatsUsername || "",
        whatsaAppPassword: UpdateUser?.whatsaAppPassword || "",
        whatsAppBalToken: UpdateUser?.whatsAppBalToken || "",
        whatsAppStatus: UpdateUser?.whatsAppStatus || "",
        whatsAppSupplementStatus: UpdateUser?.whatsAppSupplementStatus || "",
        SMTPUserName: UpdateUser?.SMTPUserName || "",
        SMTPPassword: UpdateUser?.SMTPPassword || "",
        SMTPHost: UpdateUser?.SMTPHost || "",
        SMTPFromEmail: UpdateUser?.SMTPFromEmail || "",
        SMTPPort: UpdateUser?.SMTPPort || "",
        SMTPSecure: UpdateUser?.SMTPSecure || "",
        SMTPrequireTLS: UpdateUser?.SMTPrequireTLS || "",
        SMTPStatus: UpdateUser?.SMTPStatus || "",
        SMTPSupplementStatus: UpdateUser?.SMTPSupplementStatus || "",
      };
      const response = await UpdateCompany(formData);
      if (response.status === 200) {
        detailsCompany();
        setsmsData(response);
        setSuccessResponse(true);
        setErrorResponse(false);
        setErr("");
      } else {
        setSuccessResponse(false);
        setErrorResponse(true);
        setErr(response?.data?.replyMsg);
        if (Array.isArray(response?.data?.replyMsg)) {
          let er = response?.data?.replyMsg;
          setErr(er);
        }
      }
    },
  });
  return (
    <div className="container form">
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="smsCompany" className="required form-label">
              {intl.formatMessage({ id: "MODULE.SETTING.SMSCOMPANYNAME" })}
            </label>
            <Select
              className="basic-single"
              name="smsCompany"
              id="smsCompany"
              placeholder={intl.formatMessage({ id: "PLACEHOLDER.COMPANY" })}
              value={
                formik.values.smsCompany
                  ? {
                      value: formik.values.smsCompany,
                      label: formik.values.smsCompany,
                    }
                  : null
              }
              onChange={(options) =>
                formik.setFieldValue("smsCompany", options?.value)
              }
              options={data?.sms?.map((item: any) => ({
                value: item,
                label: item,
              }))}
            />
            <div className="error mt-2">
              {formik.touched.smsCompany && formik.errors.smsCompany}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMSUSERNAME",
                })}
              </label>
              <input
                type="text"
                name="smsUsername"
                className="form-control"
                placeholder={intl.formatMessage({ id: "PLACEHOLDER.USERNAME" })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.smsUsername}
              />
              {formik.errors.smsUsername && (
                <div className="error mt-2">
                  {formik.touched.smsUsername && formik.errors.smsUsername}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMSPASSWORD",
                })}
              </label>
              <input
                type="text"
                name="smsPassword"
                className="form-control"
                placeholder={intl.formatMessage({ id: "PLACEHOLDER.PASSWORD" })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.smsPassword}
              />
              {formik.errors.smsPassword && (
                <div className="error mt-2">
                  {formik.touched.smsPassword && formik.errors.smsPassword}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMSSENDERID",
                })}
              </label>
              <input
                type="text"
                name="smsSenderID"
                className="form-control"
                placeholder={intl.formatMessage({ id: "PLACEHOLDER.SENDERID" })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.smsSenderID}
              />
              {formik.errors.smsSenderID && (
                <div className="error mt-2">
                  {formik.touched.smsSenderID && formik.errors.smsSenderID}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="smsStatus" className="required form-label">
                {intl.formatMessage({ id: "MODULE.SETTING.SMSSTATUS" })}
              </label>
              <select
                id="smsStatus"
                name="smsStatus"
                className="form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.smsStatus}
              >
                <option value="Active">
                  {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                </option>
                <option value="Inactive">
                  {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                </option>
              </select>
              {formik.errors.smsStatus && (
                <div className="error mt-2">
                  {formik.touched.smsStatus && formik.errors.smsStatus}
                </div>
              )}
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="smsSupplementStatus" className="form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMSSUPPLYMENTSTATUS",
                })}
              </label>
              <select
                id="smsSupplementStatus"
                name="smsSupplementStatus"
                className="form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.smsSupplementStatus}
              >
                <option value="Active">
                  {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                </option>
                <option value="Inactive">
                  {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                </option>
              </select>
            </div>
          </div> */}
        </div>
        <button type="submit" className="btn btn-primary btn-block m-4">
          {intl.formatMessage({
            id: "MODULE.PACKAGEPERIOD.SAVEBUTTON",
          })}
        </button>
      </Form>
    </div>
  );
};

export default SMS;
