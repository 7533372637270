import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_TOPBAR = `${API_URL}/v1/user/getdetailByID`;

export function GetTopbarItems(data: any) {
  return axios.post(GET_TOPBAR, data).catch((error) => {
    return error.response;
  });
}
