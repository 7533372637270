import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import swal from "sweetalert";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "react-phone-input-2/lib/style.css";
// import formatDate from "date-fns";
import { useIntl } from "react-intl";
import { UpdateExpenseCategory, addExpense } from "../../Redux/ExpenseCRUD";
// import InvoicePage from "./InvoicePage";
const data: any = localStorage.getItem("CountryCurrencycode");
const CountryCode = JSON.parse(data);
const ExpenseModal = ({
  show,
  setShow,
  UpdateUser,
  isUpdate,
  activeCatagory,
  getExpenseCategory,
  paymodeOpt,
}: any) => {
  const intl = useIntl();
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  // console.log("activebranch", ActiveBranch);
  const ActiveBranchID = ActiveBranch.iBranchID;
  // const ActiveBranchUUID = ActiveBranch.iBranchUUID;
  // console.log("activeEmpList", CountryCode);

  const [selectCatagory, setSelectCatagory] = useState({
    label: `${intl.formatMessage({ id: "SELECT.EXPENSE.EXPENSECATAGORY" })}`,
    value: "",
  });
  const [selectPaymentMode, setSelectPaymentMode] = useState({
    label: `${intl.formatMessage({ id: "SELECT.EXPENSE.PAYMENTMODE" })}`,
    value: "",
  });
  const [recieverName, setRecieverName] = useState<string>("");
  const [dueAmount, setDueAmount] = useState<number>(0);
  const initialDate = moment().format("YYYY-MM-DD");
  const date = new Date();
  const initialTime: string = moment(date).format("HH:mm:ss");
  const time: string = moment(initialTime, "HH:mm:ss").format("hh:mm");
  // console.log(time, "initialDAte");
  const [remarks, setRemarks] = useState<any>("");
  const [paymentDate, setPaymentDate] = useState<any>(initialDate);
  //   const [paymentTime, setPaymentTime] = useState<any>(time);
  const [chequeDate, setChequeDate] = useState<any>(initialDate);
  //   const [nextPaymentDate, setNextPaymentDate] = useState<any>(initialDate);
  const [paymentNumber, setPaymentNumber] = useState<any>();
  const [BillNo, setBillNo] = useState<any>();
  const [bankName, setBankName] = useState<any>();

  const Schemas = Yup.object().shape({
    expenseCatID: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.PACKAGES" })}`
    ),
    paidTO: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.MEMBER" })}`
    ),
    amount: Yup.number().required(
      `${intl.formatMessage({ id: "REQUIRED.INVOICE.EMPLOYEE" })}`
    ),
    expenseDate: Yup.string().required(
      `${intl.formatMessage({ id: "REQUIRED.INVOICE.PAYMENTDATE" })}`
    ),
    modeOfPayment: Yup.string().required(
      `${intl.formatMessage({ id: "REQUIRED.INVOICE.PAYMENTMODE" })}`
    ),
  });
  // create member value
  let createValue = {
    branchID: ActiveBranchID,
    expenseCatID: "",
    paidTO: "",
    amount: "",
    expenseDate: initialDate,
    modeOfPayment: "",
    chequeno: "",
    bankName: "",
    chequeDate: "",
    billNo: "",
    remarks: "",
    status: 1,
  };
  let UpdateValue = {
    expenseUUID: UpdateUser?.iExpenseUUID,
    branchID: UpdateUser?.iBranchID,
    expenseCatID: UpdateUser?.iExpenseID,
    paidTO: UpdateUser?.paid_to,
    amount: UpdateUser?.amount,
    expenseDate: initialDate,
    modeOfPayment: UpdateUser?.mode_of_paymentID,
    chequeno: UpdateUser?.cheque_no,
    bankName: UpdateUser?.bank_name,
    chequeDate: UpdateUser?.cheque_date,
    billNo: UpdateUser?.bill_no,
    remarks: UpdateUser?.remarks,
    status: UpdateUser?.eStatus,
  };
  const paymentModeOptions = [
    {
      value: "Cash",
      label: intl.formatMessage({ id: "MODULE.PAYNTMODE.CASH" }),
    },
    {
      value: "Cheque",
      label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.CHEQUE" }),
    },
    {
      value: "DemandDraft",
      label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.DD" }),
    },
    {
      value: "DirectDeposit",
      label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.DDEPOSIT" }),
    },
    {
      value: "Online",
      label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.ONLINET" }),
    },
  ];

  useEffect(() => {
    if (isUpdate && UpdateUser) {
      setSelectCatagory({
        value: UpdateUser?.iExpenseCatID,
        label: UpdateUser?.catName,
      });
      setRecieverName(UpdateUser?.paid_to);
      setDueAmount(UpdateUser?.amount);
      setBillNo(UpdateUser?.bill_no);
      setRemarks(UpdateUser?.remarks);
      setPaymentDate(UpdateUser?.dsf);
      setSelectPaymentMode({
        value: UpdateUser?.mode_of_paymentID,
        label: UpdateUser?.mode_of_payment,
      });
      setChequeDate(UpdateUser?.cheque_date);
      setPaymentNumber(UpdateUser?.cheque_no);
      setBankName(UpdateUser?.bank_name);
    }
  }, [isUpdate, UpdateUser]);

  //call the function
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isUpdate ? UpdateValue : createValue,

    validationSchema: Schemas,

    onSubmit: async (values, action) => {
      // console.log("values", values);
      if (isUpdate && UpdateUser) {
        const response = await UpdateExpenseCategory(values);
        // console.log("respone", response);
        if (response.status === 200) {
          swal(response?.data?.replyMsg, { icon: "success" });
          setShow(false);
          action.resetForm();
          getExpenseCategory();
          handleResetForm();
        }
      } else {
        const response = await addExpense(values);
        // console.log("respone", response);
        if (response.status === 200) {
          swal(response?.data?.replyMsg, { icon: "success" });
          handleResetForm();
          action.resetForm();
          getExpenseCategory();
          setShow(false);
        }
      }
    },
  });

  const handleClose = () => {
    setShow(false);
    setSelectCatagory({
      value: "",
      label: `${intl.formatMessage({
        id: "MODULE.SELECTPACKAGE.MEMBER",
      })}`,
    });
    handleResetForm();
  };

  // const showMember = async () => {
  //   const response = await activeUserList(UUID);
  //   setActiveUser(response?.data?.data);
  //   console.log("12322", response);
  // };
  const packageList = async () => {
    // const response = await enrollListofRenewal(ActiveBranchID, memberUID);
    // setPackageRenewal(response?.data?.data);
    // console.log("ressss", response);
  };

  // packageSelectionByEnrollId
  const handleMemberChange = async (Event: any) => {
    setSelectCatagory({
      value: Event.value,
      label: Event.label,
    });

    formik.setFieldValue("expenseCatID", Event.value);

    // setSelecetedMemberrenew({
    //   value: "",
    //   label: `${intl.formatMessage({
    //     id: "MODULE.SELECTPACKAGE.ENROLMENTLIST",
    //   })}`,
    // });
  };

  // const findDiscountLabel = (value: number) => {
  //   const option = discountTypeOptions.find((option) => option.value === value);
  //   return option ? option.label : null;
  // };

  //  packagedetails
  //   const renewPackagechange = async (Event: any) => {
  //     setSelecetedMemberrenew({
  //       value: Event.value,
  //       label: Event.label,
  //     });
  //     formik.setFieldValue("enrollUUID", Event.value);

  //     // const response = await enrolmentDueAmount(ActiveBranchID, Event.value);
  //     // // const response = await enrollDetailsofRenewal(ActiveBranchID, Event.value);
  //     // console.log("dsafas", response?.data?.data?.remainingAmt);
  //     // setDueAMT(response?.data?.data?.remainingAmt);
  //     // formik.setFieldValue("amount", response?.data?.data?.remainingAmt);
  //     // dueAMT = response?.data?.data?.remainingAmt;
  //     // setDueAmount(response?.data?.data?.remainingAmt);
  //   };

  const handleResetForm = () => {
    setSelectCatagory({
      label: `${intl.formatMessage({ id: "SELECT.EXPENSE.EXPENSECATAGORY" })}`,
      value: "",
    });
    setBillNo("");
    setRecieverName("");
    setDueAmount(0);
    setRemarks("");
    setPaymentDate(initialDate);
    setSelectPaymentMode({
      label: `${intl.formatMessage({ id: "SELECT.EXPENSE.PAYMENTMODE" })}`,
      value: "",
    });
    handlePaymentReset();
  };

  const handleReceiverNameChange = (Event: any) => {
    setRecieverName(Event.target.value);
    formik.setFieldValue("paidTO", Event.target.value);
  };
  const handleBillChange = (Event: any) => {
    setBillNo(Event.target.value);
    formik.setFieldValue("billNo", Event.target.value);
  };

  //DUE AMOUNT HANDLER
  const handleAmountchange = (Event: any) => {
    setDueAmount(Event.target.value);
    formik.setFieldValue("amount", Event.target.value);
  };
  //   const handleEmployeeChange = (Event: any) => {
  //     setSelectEmployee({ value: Event.value, label: Event.label });
  //     formik.setFieldValue("receiveByEmployeeID", Event.value);
  //   };
  const handlePaymentReset = () => {
    setPaymentNumber("");
    setBankName("");
    setChequeDate("");
  };
  const handlePaymentNoChange = (Event: any) => {
    setPaymentNumber(Event.target.value);
    // console.log(selectPaymentMode.value, "checkmode");
    if (selectPaymentMode.value == "Cheque") {
      formik.setFieldValue("chequeno", Event.target.value);
      // console.log(formik.values.chequeno, "checkno");
    }
  };
  const handlePaymentBankChange = (Event: any) => {
    setBankName(Event.target.value);
    if (selectPaymentMode.value == "Cheque") {
      formik.setFieldValue("bankName", Event.target.value);
    }
  };
  const handleRemarkChange = (Event: any) => {
    setRemarks(Event.target.value);
    formik.setFieldValue("remarks", Event.target.value);
  };
  const handlePaymentDateChange = (Event: any) => {
    setPaymentDate(Event.target.value);
    formik.setFieldValue("expenseDate", Event.target.value);
    // formik.setFieldValue("nextPaymentData", Event.target.value);

    const date = new Date(Event.target.value);
    // console.log("paymentDate", date.toISOString());
  };
  //   const handleTimeChange = (Event: any) => {
  //     setPaymentTime(Event.target.value);
  //     formik.setFieldValue("paymentTime", Event.target.value);
  //   };
  const handleChequeDateChange = (Event: any) => {
    setChequeDate(Event.target.value);
    formik.setFieldValue("chequeDate", Event.target.value);
  };
  const handlePaymentModeChange = (Event: any) => {
    //   formik.setFieldValue("packageUUID", Event.value);
    formik.setFieldValue("modeOfPayment", Event.value);
    setSelectPaymentMode({
      value: Event.value,
      label: Event.label,
    });
    handlePaymentReset();
  };
  //   const handleReceiverCountryCodeChange = (Event: any) => {
  //     //   formik.setFieldValue("packageUUID", Event.value);
  //     formik.setFieldValue("receiverCountryCode", Event.value);
  //     // setCountryCode({ value: Event.value, label: Event.label });
  //   };
  //   const handleReceiverMobileNoChange = (Event: any) => {
  //     //   formik.setFieldValue("packageUUID", Event.value);
  //     setMobno(Event.target.value);
  //     formik.setFieldValue("receiverMobileNo", Event.target.value);
  //   };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`${intl.formatMessage({
              id: isUpdate
                ? "MODULE.EXPENSECATEGORY.EXPENSEUPDATE"
                : "MODULE.EXPENSECATEGORY.EXPENSEADD",
            })}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body scroll-y mx-5 mx-xl-15 my-7">
          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex flex-column scroll-y me-n7 pe-7">
              <div className="p-6.5">
                <div className="row mb-6 mt-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="expenseCatID"
                        className="required form-label"
                      >
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.LIST",
                        })}
                      </label>
                      <Select
                        id="expenseCatID"
                        name="expenseCatID"
                        className="basic-single"
                        value={selectCatagory}
                        options={activeCatagory?.map((item: any) => ({
                          value: item.iExpenseCatID,
                          label: item.name,
                        }))}
                        onChange={(e) => handleMemberChange(e)}
                      />
                      {formik.touched.expenseCatID &&
                        formik.errors.expenseCatID && (
                          <div className="text-danger">
                            {formik.errors.expenseCatID}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="enrollUUID" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.RENEWAL.PACKAGE.MEMBER",
                        })}
                      </label>
                      <Select
                        id="enrollUUID"
                        name="enrollUUID"
                        className="basic-single"
                        value={selectedPackagesrenew}
                        options={packageRenewal?.map((item: any) => ({
                          value: item.iEnrolUUID,
                          label: `${item.eEnrol_Type} (${item.packageName}) `,
                        }))}
                        onChange={(e) => renewPackagechange(e)}
                      />
                      {formik.touched.enrollUUID &&
                        formik.errors.enrollUUID && (
                          <div className="text-danger">
                            {formik.errors.enrollUUID}
                          </div>
                        )}
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="paidTO" className="required form-label">
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.PAIDTO",
                        })}
                      </label>
                      <input
                        type="text"
                        id="paidTO"
                        name="paidTO"
                        className="form-control"
                        value={recieverName}
                        onChange={(e) => handleReceiverNameChange(e)}
                        // onChange={formik.handleChange("programfee")}
                        // disabled
                      />
                      {formik.touched.paidTO && formik.errors.paidTO && (
                        <div className="text-danger">
                          {formik.errors.paidTO}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="amount" className="required form-label">
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.AMOUNT",
                        })}
                      </label>
                      <input
                        type="number"
                        id="amount"
                        name="amount"
                        className="form-control"
                        value={dueAmount}
                        onChange={(e) => handleAmountchange(e)}
                      />
                      {formik.touched.amount && formik.errors.amount && (
                        <div className="text-danger">
                          {formik.errors.amount}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="receiverCountryCode"
                        className="form-label"
                      >
                        {intl.formatMessage({
                          id: "COMMON.COUNTRYCODE",
                        })}
                      </label>
                      <Select
                        id="receiverCountryCode"
                        name="receiverCountryCode"
                        className="basic-single"
                        value={countryCode}
                        options={CountryCode.map((item: any) => ({
                          value: item.countryDialCode,
                          label: `${item.countryDialCode} ${item.countryName}`,
                        }))}
                        onChange={(e) => handleReceiverCountryCodeChange(e)}
                        // onChange={formik.handleChange("programfee")}
                        // disabled
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="receiverMobileNo" className="form-label">
                        {intl.formatMessage({
                          id: "COMMON.ENTER MOBILE NUMBER",
                        })}
                      </label>
                      <input
                        type="text"
                        id="receiverMobileNo"
                        name="receiverMobileNo"
                        className="form-control"
                        value={Mobno}
                        onChange={(e) => handleReceiverMobileNoChange(e)}
                        // onChange={formik.handleChange("programfee")}
                        // disabled
                      />
                    </div>
                  </div> */}
                </div>
                <div>
                  {/* <h1 style={{ color: "#009ef7", textDecoration: "underline" }}>
                    {intl.formatMessage({
                      id: "MODULE.MEMBERS.PACKAGEDETAILS",
                    })}
                  </h1> */}
                </div>

                <div className="row mb-6 mt-7">
                  {/* <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="paymentTime" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.INVOICES.PAYMENTTIME",
                        })}
                      </label>
                      <input
                        type="time"
                        id="paymentTime"
                        name="paymentTime"
                        className="form-control"
                        value={paymentTime}
                        onChange={(e) => {
                          handleTimeChange(e);
                        }}
                        // onChange={formik.handleChange("programfee")}
                      />
                      {formik.touched.paymentTime &&
                        formik.errors.paymentTime && (
                          <div className="text-danger">
                            {formik.errors.paymentTime}
                          </div>
                        )}
                    </div>
                  </div> */}
                </div>
                <div className="row mb-6 mt-7">
                  <div>
                    <h1
                      style={{ color: "#009ef7", textDecoration: "underline" }}
                    >
                      {intl.formatMessage({
                        id: "MODULE.INVOICES.PAYMENTDETAILS",
                      })}
                    </h1>
                  </div>
                </div>
                <div className="row mb-6 mt-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="modeOfPayment"
                        className="required form-label"
                      >
                        {intl.formatMessage({
                          id: "MODULE.RECIEPT.PAYMENTMODE",
                        })}
                      </label>
                      <Select
                        id="modeOfPayment"
                        name="modeOfPayment"
                        className="basic-single"
                        value={selectPaymentMode}
                        options={paymodeOpt?.map((item: any) => ({
                          value: item.ipaymentModeID,
                          label: item.modeName,
                        }))}
                        onChange={(e) => handlePaymentModeChange(e)}
                      />
                      {formik.touched.modeOfPayment &&
                        formik.errors.modeOfPayment && (
                          <div className="text-danger">
                            {formik.errors.modeOfPayment}
                          </div>
                        )}
                    </div>
                  </div>

                  {selectPaymentMode.value === "Cheque" && (
                    <>
                      {" "}
                      <div className="col-md-4 d-none">
                        <div className="mb-3">
                          <label htmlFor="chequeno" className="form-label">
                            {intl.formatMessage({
                              id: "MODULE.INVOICES.NUMBER",
                            })}
                          </label>
                          <input
                            type="text"
                            id="chequeno"
                            name="chequeno"
                            className="form-control"
                            value={paymentNumber}
                            onChange={(e) => handlePaymentNoChange(e)}
                            // onChange={formik.handleChange("programfee")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 d-none">
                        <div className="mb-3">
                          <label htmlFor="bankName" className="form-label">
                            {intl.formatMessage({
                              id: "MODULE.INVOICES.BANK",
                            })}
                          </label>
                          <input
                            type="text"
                            id="bankName"
                            name="bankName"
                            className="form-control"
                            value={bankName}
                            onChange={(e) => {
                              handlePaymentBankChange(e);
                            }}
                            // onChange={formik.handleChange("programfee")}
                          />
                        </div>
                      </div>
                      {selectPaymentMode.value === "Cheque" && (
                        <div className="col-md-4 d-none">
                          <div className="mb-3">
                            <label htmlFor="chequeDate" className="form-label">
                              {intl.formatMessage({
                                id: "MODULE.INVOICES.CHEQUEDATE",
                              })}
                            </label>
                            <input
                              type="date"
                              id="chequeDate"
                              name="chequeDate"
                              className="form-control"
                              value={chequeDate}
                              onChange={(e) => {
                                handleChequeDateChange(e);
                              }}
                              // onChange={formik.handleChange("programfee")}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-md-4 d-none">
                    <div className="mb-3">
                      <label htmlFor="billNo" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.BILLNO",
                        })}
                      </label>
                      <input
                        type="text"
                        id="billNo"
                        name="billNo"
                        className="form-control"
                        value={BillNo}
                        onChange={(e) => handleBillChange(e)}
                        // onChange={formik.handleChange("programfee")}
                        // disabled
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="remarks" className="form-label">
                        {intl.formatMessage({
                          id: "COMMON_REMARK",
                        })}
                      </label>
                      <textarea
                        rows={2}
                        id="remarks"
                        name="remarks"
                        className="form-control"
                        value={remarks}
                        // onChange={formik.handleChange("programfee")}
                        onChange={(e) => {
                          handleRemarkChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="expenseDate" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.DATE",
                        })}
                      </label>
                      <input
                        type="date"
                        id="expenseDate"
                        name="expenseDate"
                        className="form-control"
                        value={paymentDate}
                        onChange={(e) => {
                          handlePaymentDateChange(e);
                        }}
                        // onChange={formik.handleChange("programfee")}
                      />
                      {formik.touched.expenseDate &&
                        formik.errors.expenseDate && (
                          <div className="text-danger">
                            {formik.errors.expenseDate}
                          </div>
                        )}
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="nextPaymentDate" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.INVOICES.NEXTPAYMENTDATE",
                        })}
                      </label>
                      <input
                        type="date"
                        id="nextPaymentDate"
                        name="nextPaymentDate"
                        className="form-control"
                        value={nextPaymentDate}
                        onChange={(e) => {
                          setNextPaymentDate(e.target.value);
                          formik.setFieldValue(
                            "nextPaymentDate",
                            e.target.value
                          );
                        }}
                        // onChange={formik.handleChange("programfee")}
                      />
                    </div>
                  </div> */}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-4"
                >
                  {`${intl.formatMessage({
                    id: "MODULE.RENEWAL.SUBMIT",
                  })}`}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenseModal;
