import { GetTopbarItems } from "./TopbarCRUD";

export const FETCH_DATA_TOPBAR_REQUEST = "FETCH_DATA_TOPBAR_REQUEST";
export const FETCH_DATA_TOPBAR_SUCCESS = "FETCH_DATA_TOPBAR_SUCCESS";
export const FETCH_DATA_TOPBAR_FAILURE = "FETCH_DATA_TOPBAR_FAILURE";

// Action Creators (with Thunk)
export const fetchDataForBranch = (branchId: string) => {
  return async (dispatch: any) => {
    // Dispatch a request action to indicate the API call is starting
    dispatch({ type: FETCH_DATA_TOPBAR_REQUEST });

    try {
      const obj = { branchID: branchId };
      // Make the API call (replace with your actual API URL)
      const response = await GetTopbarItems(obj);
      // console.log(response, "hey buddy");

      const data = response?.data;

      // Dispatch a success action with the data if the API call is successful
      dispatch({
        type: FETCH_DATA_TOPBAR_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      // Dispatch a failure action if the API call fails
      dispatch({
        type: FETCH_DATA_TOPBAR_FAILURE,
        payload: error.message,
      });
    }
  };
};
