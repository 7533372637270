/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import * as auth from "../redux/AuthRedux";
import { GetPlans, register, getCountryCodeList } from "../redux/AuthCRUD";
import { Link, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import PlanListmodel from "./planmodel/PlanListmodel";

// import {CountryCode} from '../data/CountryCode'
import ShowPlanModal from "./planmodel/ShowPlanModel";
import ShowSelectedPlanModel from "./planmodel/ShowSelectedPlanModel";
import Select from "react-select";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useIntl } from "react-intl";
import { values } from "lodash";

const initialValues = {
  user_first_name: "",
  user_last_name: "",
  user_email: "",
  user_country_code: "+91",
  user_mobile: "",
  user_password: "",
  changepassword: "",
  address: "",
  country_currency_id: "101",
  user_singup_type: "website",
  status: "1",
  acceptTerms: false,
  user_role_id: "",
  plan_id: "",
  plan_amount: "",
};

export function Registration() {
  const intl = useIntl();
  const registrationSchema = Yup.object().shape({
    user_first_name: Yup.string()
      .min(3, intl.formatMessage({ id: "VALIDATION.EMAIL.MIN" }))
      .max(50, intl.formatMessage({ id: "VALIDATION.EMAIL.MAX" }))
      .required(
        intl.formatMessage({ id: "VALIDATION.PROFILE.FIRST_NAME.REQUIRED" })
      ),
    user_email: Yup.string()
      .email(intl.formatMessage({ id: "VALIDATION.EMAIL.WRONG_FORMAT" }))
      .min(3, intl.formatMessage({ id: "VALIDATION.EMAIL.MIN" }))
      .max(50, intl.formatMessage({ id: "VALIDATION.EMAIL.MAX" }))
      .required(intl.formatMessage({ id: "VALIDATION.EMAIL.REQUIRED" })),
    user_mobile: Yup.string().required(
      intl.formatMessage({ id: "VALIDATION.MOBILENO" })
    ),
    address: Yup.string().required(
      intl.formatMessage({ id: "VALIDATION.ADDRESS" })
    ),
    user_last_name: Yup.string()
      .min(3, intl.formatMessage({ id: "VALIDATION.EMAIL.MIN" }))
      .max(50, intl.formatMessage({ id: "VALIDATION.EMAIL.MAX" }))
      .required(
        intl.formatMessage({ id: "VALIDATION.PROFILE.LAST_NAME.REQUIRED" })
      ),
    user_password: Yup.string()
      .min(3, intl.formatMessage({ id: "VALIDATION.EMAIL.MIN" }))
      .max(50, intl.formatMessage({ id: "VALIDATION.EMAIL.MAX" }))
      .required(intl.formatMessage({ id: "VALIDATION.PASSWORD.REQUIRED" })),
    changepassword: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.PASSWORD.REQUIRED" }))
      .when("user_password", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("user_password")],
          intl.formatMessage({ id: "VALIDATION.PASSWORD.MATCH" })
        ),
      }),
    user_role_id: Yup.string().required(
      intl.formatMessage({ id: "VALIDATION.USERROLE" })
    ),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({ id: "VALIDATION.TERM&CONDTIONS" })
    ),
  });
  const [from, setFrom] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState<any>(null);
  const [extraPlan, setExtraPlan] = useState<any>(null);
  const [showPassword, setShowpassword] = useState(false);
  const [error, setError] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlanID, setSelectedPlanID] = useState<any>(null);
  const [selectedPlanData, setSelectedPlanData] = useState<any>(null);
  const [selectPlan, setSelectPlan] = useState<any>(null);
  //selected plan data
  const [planData, setPlanData] = useState<any>(null);

  //show list plan model
  const [showPlanlist, setshowPlanlist] = useState(false);
  const errorMapping: any = {};
  //show plan data
  const [showPlan, SetshowPlan] = useState(false);
  const [showSelectedPlan, SetshowSelectedPlan] = useState(false);
  const [CountryCode, setCountryCode] = useState([]);
  const [selectCountry, setSelectCountry] = useState<any>();
  const [selectCurrency, setSelectCurrency] = useState<any>("₹");
  //temp selected plan
  // get plans api
  const getAllPlans = useCallback((membershipType: string) => {
    GetPlans({
      type: membershipType,
    }).then((responce: any) => {
      setPlan(responce.data.data);
      setExtraPlan(responce?.data?.extraData);
      // console.log(responce);
    });
  }, []);

  useEffect(() => {
    getCountryCodeList()
      .then((responce: any) => {
        setCountryCode(responce?.data?.data);
      })
      .catch((err) => console.log(err, "hererer"));
  }, []);

  // Function to handle the eye icon click and open the modal
  const handlePlanDataModalOpen = (planData: any) => {
    setSelectedPlanData(planData);
    setIsModalOpen(true);
  };

  const handlePlanClick = (planID: any, price: any) => {
    setSelectedPlanID(planID);
    const id: string = planID.toString();
    const planprice: string = price.toString();
    formik.setFieldValue("plan_id", id);
    formik.setFieldValue("plan_amount", planprice);
  };

  useEffect(() => {
    if (planData != null) {
      if (planData.membership_type === "fitnessCenter") {
        formik.setFieldValue("user_role_id", "2");
      }
      if (planData.membership_type === "channelPartner") {
        formik.setFieldValue("user_role_id", "3");
      }
    }
  }, [planData]);

  const handleRoleChange = (event: any) => {
    const selectedRole = event.target.value;
    setSelectPlan(selectedRole);
    // Clear the selected plan and its related fields when the
    // Get the plans for the selected role

    if (selectedRole === "2") {
      getAllPlans("fitnessCenter");
      setshowPlanlist(true);
    } else if (selectedRole === "3") {
      getAllPlans("channelPartner");
      setshowPlanlist(true);
    } else {
      setPlan(null); // Clear plans if no valid role is selected
      setshowPlanlist(false);
    }
  };
  const handleCountryChange = (Event: any) => {
    setSelectCountry({ value: Event.value, label: Event.label });
    formik.setFieldValue("user_country_code", Event.value);
    if (Event.value === "+91") {
      setSelectCurrency("₹");
    } else {
      setSelectCurrency("$");
    }
    // const result: any = CountryCode?.find(
    //   (item: any) => item.countryDialCode === Event.value
    // );
    // console.log(result);
    // setSelectCurrency(result?.currencySymbol);
  };
  // "₹"
  // "$"
  let history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      // console.log(values);
      if (selectedPlanID === null) {
        toast.error("please select any plan");
        setLoading(false);
        return;
      }
      setTimeout(() => {
        register(values).then((responce: any) => {
          setLoading(false);
          if (responce?.status === 200) {
            toast.success(responce?.data?.replyMsg);
            history.push(`/auth/login`);
            // history.push(`/auth/checkout`, {
            //   planData,
            //   userData: values,
            //   userId: responce?.data?.userId,
            // });
            setLoading(false);
            setSubmitting(false);
            setError(null);
          } else {
            setLoading(false);
            setSubmitting(false);
            // setStatus("Registration process has broken");
            responce?.data?.replyMsg.forEach((error: any) => {
              const field = Object.keys(error)[0];
              const errorMessage = error[field];
              errorMapping[field] = errorMessage;
            });
            setError(errorMapping);
            // console.log(errorMapping, "error");
          }
          //  dispatch(auth.actions.login(accessToken))
        });
      }, 1000);
    },
  });

  return (
    <div
      className="bg-white  rounded shadow-lg p-10 p-lg-15 mx-auto"
      style={{ width: "100%", maxWidth: "900px" }}
    >
      <form
        className="form fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className="mb-10 text-center">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "REGISTER.CREATE" })}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            {intl.formatMessage({ id: "REGISTER.ALREADYACCOUNT" })}
            <Link
              to="/auth/login"
              className="link-primary fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              {intl.formatMessage({ id: "REGISTER.LOGINHERE" })}
            </Link>
          </div>
          {/* end::Link */}
        </div>
        {/* end::Heading */}

        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {/* begin::Form group user_first_name */}
        <div className="row fv-row mb-4">
          <div className="col-xl-6">
            <label className=" required form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "COMMON.FNAME" })}
            </label>
            <input
              placeholder={intl.formatMessage({ id: "COMMON.FNAME" })}
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("user_first_name")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.user_first_name &&
                    formik.errors.user_first_name,
                },
                {
                  "is-valid":
                    formik.touched.user_first_name &&
                    !formik.errors.user_first_name,
                }
              )}
            />
            {formik.touched.user_first_name && formik.errors.user_first_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span className="error">{formik.errors.user_first_name}</span>
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-6">
            {/* begin::Form group user_last_name */}

            <label className="required form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "COMMON.LNAME" })}
            </label>
            <input
              placeholder={intl.formatMessage({ id: "COMMON.LNAME" })}
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("user_last_name")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.user_last_name &&
                    formik.errors.user_last_name,
                },
                {
                  "is-valid":
                    formik.touched.user_last_name &&
                    !formik.errors.user_last_name,
                }
              )}
            />
            {formik.touched.user_last_name && formik.errors.user_last_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span className="error">{formik.errors.user_last_name}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        {/* end::Form group */}

        {/* begin::Form group user_email */}
        <div className="row fv-row ">
          <div className="col-xl-6">
            <label className="required form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "COMMON.EMAIL" })}
            </label>
            <input
              placeholder={intl.formatMessage({ id: "COMMON.EMAIL" })}
              type="user_email"
              autoComplete="off"
              {...formik.getFieldProps("user_email")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.user_email && formik.errors.user_email,
                },
                {
                  "is-valid":
                    formik.touched.user_email && !formik.errors.user_email,
                }
              )}
            />
            {formik.touched.user_email && formik.errors.user_email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span className="error">{formik.errors.user_email}</span>
                </div>
              </div>
            )}

            {error != null && error.user_email ? (
              <div className="error">{error.user_email}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-xl-3">
            <label className="required form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "COMMON.COUNTRYCODE" })}
            </label>
            <Select
              placeholder={intl.formatMessage({ id: "COMMON.COUNTRYCODE" })}
              className="basic-single"
              value={selectCountry}
              onChange={(e) => handleCountryChange(e)}
              options={CountryCode?.map((element: any) => {
                return {
                  value: element.countryDialCode,
                  label: element.countryDialCode + `(${element.countryName})`,
                };
              })}
            />
            {/* {CountryCode.map((element: any) => {
                return (
                  <option value={element.countryDialCode}>
                    {element.currencyName}({element.countryDialCode})
                  </option>
                );
              })}
            </select> */}
            {/* <PhoneInput
              country={'india'}
              value={formik.values.user_country_code}
              onChange={(countryCode) => formik.setFieldValue('user_coun try_code', countryCode)}
            /> */}
          </div>

          <div className="col-xl-3">
            <div className="">
              <label className="required form-label fw-bolder text-dark fs-6">
                {intl.formatMessage({ id: "COMMON.MOBILE" })}
              </label>
              <input
                placeholder={intl.formatMessage({ id: "COMMON.MOBILE" })}
                type="tel"
                {...formik.getFieldProps("user_mobile")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.user_mobile && formik.errors.user_mobile,
                  },
                  {
                    "is-valid":
                      formik.touched.user_mobile && !formik.errors.user_mobile,
                  }
                )}
              />
              {formik.touched.user_mobile && formik.errors.user_mobile && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="error">{formik.errors.user_mobile}</span>
                  </div>
                </div>
              )}
              {error != null && error.user_mobile ? (
                <div className="error">{error.user_mobile}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* end::Form group */}
        {/* begin::form Groupe user_Mobile */}
        {/* <div className="row fv-row"></div> */}

        {/* end::form Groupe user_Mobile */}

        {/* begin::Form group user_Password */}
        <div className="row">
          <div
            className="col-xl-6 mb-4 fv-row"
            data-kt-user_password-meter="true"
          >
            <div className="mb-1">
              <label className="required form-label fw-bolder text-dark fs-6">
                {intl.formatMessage({ id: "COMMON.PASSWORD" })}
              </label>
              <div className="position-relative mb-3">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder={intl.formatMessage({ id: "COMMON.PASSWORD" })}
                  autoComplete="off"
                  {...formik.getFieldProps("user_password")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    {
                      "is-invalid":
                        formik.touched.user_password &&
                        formik.errors.user_password,
                    },
                    {
                      "is-valid":
                        formik.touched.user_password &&
                        !formik.errors.user_password,
                    }
                  )}
                />
                <span
                  className="position-absolute top-50 end-0 translate-middle-y pe-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowpassword(!showPassword)}
                >
                  {showPassword ? (
                    <FaEyeSlash className="fs-4 text-primary me-7" />
                  ) : (
                    <FaEye className="fs-4 text-muted me-7" />
                  )}
                </span>
              </div>
              {formik.touched.user_password && formik.errors.user_password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="error">{formik.errors.user_password}</span>
                  </div>
                </div>
              )}
              {error != null && error.user_password ? (
                <div className="error">{error.user_password}</div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm user_password */}
          <div className="col-xl-6 fv-row mb-4">
            <label className="required form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "COMMON.CPASSWORD" })}
            </label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder={intl.formatMessage({ id: "COMMON.CPASSWORD" })}
              autoComplete="off"
              {...formik.getFieldProps("changepassword")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.changepassword &&
                    formik.errors.changepassword,
                },
                {
                  "is-valid":
                    formik.touched.changepassword &&
                    !formik.errors.changepassword,
                }
              )}
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span className="error">{formik.errors.changepassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Form group */}

        <div className="row py-2">
          <div className="">
            <label className="required form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "COMMON.ADDRESS" })}
            </label>
            <textarea
              rows={4}
              placeholder={intl.formatMessage({ id: "COMMON.ADDRESS" })}
              autoComplete="off"
              {...formik.getFieldProps("address")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
                // {
                //   "is-invalid":
                //     formik.touched.changepassword &&
                //     formik.errors.changepassword,
                // },
                // {
                //   "is-valid":
                //     formik.touched.changepassword &&
                //     !formik.errors.changepassword,
                // }
              )}
            />
            <div className="error">
              {formik.touched.address && formik.errors.address}
            </div>
          </div>
        </div>
        {/* start::Role Section */}
        <div className="row ">
          <div className="col mx-auto mb-2">
            <label className=" required form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "COMMON.SELECTROLE" })}
            </label>
            <div className="relative z-20 bg-transparent dark:bg-form-input">
              <select
                name="user_role_id"
                placeholder={intl.formatMessage({ id: "COMMON.SELECTROLE" })}
                className="form-select form-select-solid mb-3 mb-lg-0"
                onChange={(e) => {
                  handleRoleChange(e);
                }}
              >
                <option value="">
                  {intl.formatMessage({ id: "COMMON.SELECT" })}
                </option>
                <option value="2">
                  {intl.formatMessage({ id: "REGISTRATION.FITNESCENTER" })}
                </option>
                <option value="3">
                  {" "}
                  {intl.formatMessage({ id: "REGISTRATION.CHANNELPARTNER" })}
                </option>
              </select>
              <div className="error">
                {formik.touched.user_role_id && formik.errors.user_role_id}
              </div>
              {error != null && error.user_role_id ? (
                <div className="error">{error.user_role_id}</div>
              ) : (
                ""
              )}
              <span className="absolute right-4 top-1/2 z-30 -translate-y-1/2"></span>
            </div>
          </div>
          {planData !== null && (
            <div className="col-md-6  mb-2">
              <div className="row">
                <div className="col-6">
                  {planData && showPlan && (
                    <>
                      {" "}
                      <label className="form-label fw-bolder text-dark fs-6">
                        {intl.formatMessage({ id: "COMMON.SELECTEDPLAN" })}
                      </label>
                      <div className="form-control form-control-lg form-control-solid">
                        {/* <span className="plan-detail-label">Plan :</span> */}
                        <span className="">{planData.membershipTitle}</span>
                        {/* <span className="plan-detail-value">
                              <button
                                className="btn btn-primary btn-sm"
                                type="button"
                                onClick={() => {
                                  setshowPlanlist(true);
                                }}
                              >
                                View Details
                              </button>
                            </span> */}

                        {/* <div className=" col-6"></div> */}
                      </div>
                      {/* <div className="plan-detail-item">
                    <span className="plan-detail-label">Plan Description:</span>
                    <span className="plan-detail-value">
                      {planData.description}
                    </span>
                  </div>
                  <div className="plan-detail-item">
                    <span className="plan-detail-label">Price:</span>
                    <span className="plan-detail-value">
                      ₹{planData.price} / {planData.days} days
                    </span>
                  </div> */}
                      {/* </div> */}
                    </>
                  )}
                </div>
                <div className="col-6  ">
                  <button
                    className="btn btn-primary fs-7 mt-8"
                    type="button"
                    onClick={() => {
                      setshowPlanlist(true);
                      setFrom(true);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.CHANGEPLAN" })}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* end:role Section */}

        {/* selected plan change start */}
        {/* <div className="row mb-4 p-4">
          {planData && showPlan && (
            <>
              {" "}
              <h3 className="plan-detail-title">Selected Plan Details</h3>
              <div className="selected-plan-details p-2  ">
                <div className="row ">
                  <div className="col-12 d-flex align-items-center">
                    {/* <span className="plan-detail-label">Plan :</span> */}
        {/* <span className="me-2">{planData.membershipTitle}</span>
                    <span className="plan-detail-value">
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={() => {
                          setshowPlanlist(true);
                        }}
                      >
                        View Details
                      </button>
                    </span>
                  </div> */}
        {/* <div className=" col-6"></div> */}
        {/* </div> */}
        {/* <div className="plan-detail-item">
                    <span className="plan-detail-label">Plan Description:</span>
                    <span className="plan-detail-value">
                      {planData.description}
                    </span>
                  </div>
                  <div className="plan-detail-item">
                    <span className="plan-detail-label">Price:</span>
                    <span className="plan-detail-value">
                      ₹{planData.price} / {planData.days} days
                    </span>
                  </div> */}
        {/* </div> */}
        {/* </>
          )}
        </div> */}

        {/* selected plan change end  */}
        {/* begin::Form group */}
        <div className="text-center mt-5">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-25 mb-5"
          >
            {!loading && (
              <span className="indicator-label">
                {" "}
                {intl.formatMessage({ id: "COMMON.SUBMIT" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "COMMON.PLEASEWAIT" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login" className="ms-5">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-25 mb-5"
            >
              {intl.formatMessage({ id: "COMMON.CANCEL" })}
            </button>
          </Link>
        </div>
        {/* end::Form group */}

        {/* //model to show the planlist
         */}
        <PlanListmodel
          plan={plan}
          extraData={extraPlan}
          setshowPlanlist={setshowPlanlist}
          showPlanlist={showPlanlist}
          selectedPlanID={selectedPlanID}
          setSelectedPlanID={setSelectedPlanID}
          currencySymbol={selectCurrency}
          handlePlanClick={handlePlanClick}
          handlePlanDataModalOpen={handlePlanDataModalOpen}
          setPlanData={setPlanData}
          SetshowPlan={SetshowPlan}
          from={from}
          setFrom={setFrom}
          roleID={selectPlan}
        />
        {/* {console.log(object)} */}
        {/* Bootstrap Modal */}
        {/* <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{selectedPlanData?.plan?.membershipTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='p-5'>
              <div className='text-center mb-5'>
                <h2 className='fw-bold'>{selectedPlanData?.plan?.membershipTitle}</h2>
                <p className='text-muted fs-5'>{selectedPlanData?.plan?.description}</p>
                <p className='text-primary fw-bold fs-3'>
                  ₹{selectedPlanData?.plan?.price} / {selectedPlanData?.plan?.days} days
                </p>
              </div>
              <ul className='list-group mb-5 '>
                <li className='list-group-item py-4'>
                  <span className='fw-bold'>Max Inquiries Allowed:</span>{' '}
                  {selectedPlanData?.plan?.MaxInquiry}
                </li>
                <li className='list-group-item py-4'>
                  <span className='fw-bold'>Max Members Allowed:</span>{' '}
                  {selectedPlanData?.plan?.maxMember}
                </li>
                <li className='list-group-item py-4'>
                  <span className='fw-bold'>Branches Allowed:</span>{' '}
                  {selectedPlanData?.plan?.noOfBrachAllow}
                </li>
              </ul>
              <div>
                <h4 className='text-center mb-4'>What's included:</h4>
                <ul className='list-group'>
                  {selectedPlanData?.modules?.map((item: any, index: number) => (
                    <li
                      key={index}
                      className='list-group-item d-flex justify-content-between align-items-center py-4'
                    >
                      {item.moduleName}
                      <span
                        className={`badge ${
                          item.moduleName ? 'bg-success' : 'bg-danger'
                        } rounded-pill`}
                      >
                        {item.moduleName ? 'Included' : 'Not Included'}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
        <ShowPlanModal
          isModalOpen={isModalOpen}
          selectedPlanData={selectedPlanData}
          setIsModalOpen={setIsModalOpen}
        />

        <ShowSelectedPlanModel
          isModalOpen={showSelectedPlan}
          selectedPlanData={planData}
          setIsModalOpen={SetshowSelectedPlan}
        />
      </form>
    </div>
  );
}
