import {
  FETCH_DATA_TOPBAR_FAILURE,
  FETCH_DATA_TOPBAR_REQUEST,
  FETCH_DATA_TOPBAR_SUCCESS,
} from "./TopbarAction";

// Reducer
interface TopbarState {
  topbarData: any | null;
  loading: boolean;
  error: string | null;
}

const initialState: TopbarState = {
  topbarData: null,
  loading: false,
  error: null,
};

const topbarReducer = (state = initialState, action: any): TopbarState => {
  switch (action.type) {
    case FETCH_DATA_TOPBAR_REQUEST:
      return { ...state, loading: true, error: null }; // Set loading to true while fetching
    case FETCH_DATA_TOPBAR_SUCCESS:
      return { ...state, loading: false, topbarData: action.payload }; // Update the state with the fetched data
    case FETCH_DATA_TOPBAR_FAILURE:
      return { ...state, loading: false, error: action.payload }; // Set the error message if the fetch fails
    default:
      return state;
  }
};

export default topbarReducer;
