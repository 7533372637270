import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetFoodItem: "[GetFoodItem] Action",
};

const initialAuthState: ICenterState = {
  foodItem: undefined,
};

export interface ICenterState {
  foodItem?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["foodItem"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetFoodItem: {
        const foodItem = action.payload?.foodItem;
        return { ...state, foodItem };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetFoodItem: (foodItem: string) => ({
    type: actionTypes.GetFoodItem,
    payload: { foodItem },
  }),
};
