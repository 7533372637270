import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetBodyMeasurement: "[GetBodyMeasurement] Action",
};

const initialAuthState: ICenterState = {
  bodyMeasuremnet: undefined,
};

export interface ICenterState {
  bodyMeasuremnet?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["bodyMeasuremnet"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetBodyMeasurement: {
        const bodyMeasuremnet = action.payload?.bodyMeasuremnet;
        return { ...state, bodyMeasuremnet };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  GetBodyMeasurement: (bodyMeasuremnet: string) => ({
    type: actionTypes.GetBodyMeasurement,
    payload: { bodyMeasuremnet },
  }),
};
