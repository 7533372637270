import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  GetCenters: '[GetCenters] Action',
}

const initialAuthState: ICenterState = {
  fitnesscenters: undefined,
}

export interface ICenterState {
  fitnesscenters?: any
}

export const reducer = persistReducer(
  { storage, key: 'v100-demo1-auth', blacklist: ['fitnesscenters'] },
  (state: ICenterState = initialAuthState, action: ActionWithPayload<ICenterState>) => {
    switch (action.type) {
      case actionTypes.GetCenters: {
        const fitnesscenters = action.payload?.fitnesscenters
        return { ...state, fitnesscenters }
      }
      default:
        return state
    }
  }
)

export const actions = {
  GetCenters: (fitnesscenters: string) => ({ type: actionTypes.GetCenters, payload: { fitnesscenters } }),
}
